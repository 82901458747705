<template>
    <v-container fluid>
  
      <TheTitle :text="title" :icon="pageIcon">
        <v-row justify="center">
          <v-col md="8" sm="10" cols="10">
            <v-text-field
            placeholder="Procurar"
              dense
              solo
              background-color="#FFFFFF"
              hide-details
              v-model="search"            
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="4" cols="4">
              <v-btn block color="amber darken-4" dark @click="dialogSave()">
                  <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
              </v-btn>
          </v-col>
        </v-row>
      </TheTitle>
  
      <v-card class="pa-5 full-height">
        <v-data-table
            :headers="header"
            :items="objects"
            dense
            locale="pt-BR"
            :search="search"
            :items-per-page="10"
        >
          <template v-slot:item.idResponsible="{ item }" >
            {{ getPersonName(item.idResponsible) }}
          </template>
          <template v-slot:item.endDate="{ item }">
            <span v-if="item.endDate == '0000-00-00 00:00:00'">Em Aberto</span>
            <span v-else>{{ item.endDate | formatDate }}</span>
          </template>

          <template v-slot:item.risk="{ item }">
            <v-chip label small text-color="white" :color="getRiskColor(item.risk)">
              {{ item.risk }}
            </v-chip>
          </template>

          <template v-slot:item.startDate="{ item }">
            {{ item.startDate | formatDate }}
          </template>

          <template v-slot:item.status="{ item }" >
            <v-chip label small text-color="white" :color="getStatusColor(item.status)">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <TheToolTip label="Vizualizar">
              <v-btn icon small tile v-if="item.status == 'Finalizado'" >
                <v-icon @click="dialogView1(item)">mdi mdi-file-eye-outline</v-icon>
              </v-btn>
            </TheToolTip>
            <TheToolTip label="Atividades">
              <v-btn icon small tile>
                <v-icon @click="activity(item.id)">mdi mdi-run-fast</v-icon>
              </v-btn>
            </TheToolTip>
            <TheToolTip label="Recursos">
              <v-btn icon small tile>
                <v-icon @click="resource(item.id)">mdi mdi-currency-usd</v-icon>
              </v-btn>
            </TheToolTip>
            <TheToolTip label="Editar" v-if="item.status !== 'Finalizado'">
              <v-btn icon small tile>
                <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
              </v-btn>
            </TheToolTip>
            <TheToolTip label="Remover" v-if="item.status !== 'Finalizado'">
              <v-btn icon small tile>
                <v-icon @click="remove(item.id)">mdi-minus</v-icon>
              </v-btn>
            </TheToolTip>
          </template>
        </v-data-table>
      </v-card>
  
      <!-- MODAL -->
      <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="900px">        
        <template>
          <v-form v-model="valid" ref="form1">
            <v-divider class="my-2 mb-3"></v-divider>
            <v-row>
              <v-col md="6" sm="12" cols="12">
                <b>Titulo da ação *</b>
                <v-text-field
                  :rules="[requiredRule, spaceRule]"
                  v-model="object.title"
                  outlined
                  tile
                  dense
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="12" cols="12">              
                <b> Responsável *</b>
                <v-select
                  :items="people"
                  v-model="object.idResponsible"
                  item-text="name"
                  item-value="id"
                  class="my-2"
                  dense
                  tile
                  outlined
                  color="primary"
                  :rules="[requiredRule, spaceRule]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" sm="12" cols="12">
                <b> Data de Inicio *</b>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="object.startDate"
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      @input="date = parseDate(object.startDate)"
                      v-on="on"
                      tile
                      dense
                      outlined
                      class="mt-2"
                      :rules="[requiredRule, dateBirthRule]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :rules="requiredRule"
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="4" sm="12" cols="12">
                <b>Status</b>
                <v-select
                  v-model="object.status"
                  :items="status"
                  outlined
                  tile
                  dense
                  class="mt-2"
                  color="primary"
                  :rules="[requiredRule, spaceRule]"
                ></v-select>                    
              </v-col>
              <v-col md="4" sm="12" cols="12">
                <b>Risco</b>
                <v-select
                  v-model="object.risk"
                  :items="risk"
                  outlined
                  tile
                  dense
                  class="mt-2"
                  color="primary"
                  :rules="[requiredRule, spaceRule]"
                ></v-select>                    
              </v-col>
            </v-row> 
            
            <v-row>
              <v-col cols="12">
                <b>Descrição *</b>
                <v-textarea
                  outlined
                  rows="3"
                  v-model="object.description"
                  class="my-2"
                  auto-grow
                  :rules="[requiredRule, spaceRule]"
                ></v-textarea>
              </v-col>
            </v-row>  
            <v-divider class="my-2"></v-divider>
  
          </v-form>
        </template>
        
        <template v-slot:actions="">
          <v-btn
            small
            depressed
            color="primary"
            class="mt-2"
            dark
            @click="resolveForm()"
          >
            <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
            <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
          </v-btn>
          <v-spacer></v-spacer>
          <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
        </template>  
      </TheDialog>

      <!-- VISUALIZAÇÃO -->
      <template>
        <v-dialog v-model="dialogView" max-width="900px">
          <v-card>
            <v-card-title>
                <span class="headline">Detalhes do Plano de Segurança</span>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col md="2" sm="12" cols="12">
                  <b>COD</b>
                  <v-text-field
                    v-model="objectView.id"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <b>Nome da Ação</b>
                  <v-text-field
                    v-model="objectView.title"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <b>Responsável</b>
                  <v-text-field
                    placeholder="Selecione"
                    v-model="objectView.name"
                    class="my-2"
                    dense
                    hide-details
                    outlined
                    color="primary"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="mt-2">
                <v-col md="6" sm="12" cols="12">
                  <b> Data de inicio </b>
                  <v-text-field
                    v-model="objectView.startDate"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>
                </v-col>                           
                <v-col md="6" sm="12" cols="12">
                  <b>Data de Conclusão</b>
                  <v-text-field
                    v-model="objectView.endDate"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>                   
                </v-col>
              </v-row>
              <v-divider class="my-5"></v-divider>
              <v-row class="mt-1">
                <v-col md="6" sm="12" cols="12">
                  <b>Risco</b>
                  <v-text-field
                    v-model="objectView.risk"
                    :items="status"
                    outlined
                    tile
                    dense
                    class="mt-2"
                    color="primary"
                    disabled
                  ></v-text-field>                    
                </v-col>
                <v-col md="6" sm="12" cols="12">
                  <b>Status</b>
                  <v-text-field
                    v-model="objectView.status"
                    :items="status"
                    outlined
                    tile
                    dense
                    class="mt-2"
                    color="primary"
                    disabled
                  ></v-text-field>                    
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="mt-2">
                <v-col cols="12">
                  <b> Descrição da Ação </b>
                  <v-textarea
                    outlined
                    rows="3"
                    v-model="objectView.description"
                    class="my-2"
                    auto-grow
                    disabled
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="closeDialog()">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

    </v-container>
  </template>
  
  <script>
  import TheTitle from "@/components/TheTitle"
  import TheDialog from "@/components/TheDialog"
  import TheToolTip from "@/components/TheToolTip"
  import { mask } from "vue-the-mask"
  import { baseApiUrl, showError } from "@/global"
  import axios from "axios"
  import moment from 'moment'
  
  export default {
  
    // name: "Plano de Açao de Segurança",
    directives: { mask },
    components: {
      TheTitle,
      TheDialog,
      TheToolTip,
    },
    
    data: () => ({
      baseRoute: "security-plan",
      title: "Plano de Ação de Segurança",
      pageIcon: "mdi mdi-shield-lock",
  
      people: [],
      objects: [],
      object: {},
      objectView: [],

      menu1: false,
      date: null,

      dialogView: false,
      dialog: false,
      search: null,
      valid: false,
      error: false,
      edit: false,
      loading: false,
  
      header: [
        {text: 'Codigo', value: 'id', width: '10%'},
        {text: 'Titulo da Ação', value: 'title'},
        {text: 'Responsável', value: 'idResponsible'},
        {text: 'Risco', value: 'risk'},
        {text: 'Status', value: 'status'},
        {text: 'Data de inicio', value: 'startDate'},
        {text: 'Data de Conclusão', value: 'endDate'},
        { text: "Ações", value: "action", width: "12%" },
      ], 
      status: [
        {text: 'Pendente', value: 'Pendente'},
        {text: 'Em andamento', value: 'Em andamento'},
        {text: 'Finalizado', value: 'Finalizado'},
      ],   
      risk: [
        {text: 'Baixo', value: 'Baixo'},
        {text: 'Médio', value: 'Médio'},
        {text: 'Alto', value: 'Alto'},
      ],   
    }),

    watch: {
      date() {
        this.object.startDate = this.date
        this.object.startDate = moment(this.object.startDate).format('DD/MM/YYYY')
      },
    },

    computed: {
      getPersonName() {
        return function (idResponsible) {
          const person = this.people.find((el) => el.id === idResponsible);
          if (person) {
            return person.name;
          } else {
            return "";
          }
        };
      },
    },
  
    methods: {  

      loadPerson(){
        this.$store.dispatch("setLoadingInfo", true)
        const url = `${baseApiUrl}/people`
        
        return axios
          .get(url)
          .then((res => {
            this.$store.dispatch("setLoadingInfo", false)
            this.people = res.data
          }))
      },
  
      async loadData() {

        this.$store.dispatch("setLoadingInfo", true)
        const url = `${baseApiUrl}/${this.baseRoute}`

        const res = await axios.get(url)
        this.$store.dispatch("setLoadingInfo", false)
        this.objects = res.data
      },
  
      dialogSave() {
        this.object = {}
        this.edit = false
        this.dialog = true
      },
  
      dialogUpdate(item) {
        this.object = item
        this.edit = true
        this.dialog = true

        this.object.startDate = moment(this.object.startDate).format('DD/MM/YYYY')
      },

      dialogView1(item) {
        this.objectView = item;
        this.objectView.startDate = moment(new Date(this.objectView.startDate)).format('DD/MM/YYYY');
        this.objectView.endDate = moment(new Date(this.objectView.endDate)).format('DD/MM/YYYY');
        this.dialogView = true;
      },  
      
      closeDialog() {
        this.objectView = {}
        this.dialogView = false
        this.loadData()  
      },
  
      resolveForm(){
        if (!this.valid){
          this.$store.dispatch("setErrorInfo", {
            open: true,
            text: "Os campos não foram preenchidos corretamente, você pode verificar?",
            button: "Ok!",
          })
          return
        }
        if(this.edit){
          this.update()
        } else {
          this.save()
        }
      },
  
      save(){
        this.error = false
        const url = `${baseApiUrl}/${this.baseRoute}`
        const objects = this.object

        const startDate = this.object.startDate.split('/')
        const parseDate = `${startDate[2]}-${startDate[1]}-${startDate[0]}` 
        objects.startDate = parseDate

        if(objects.status == 'Finalizado'){
          objects.endDate = new Date
        }
                
        axios
          .post(url, objects)
          .then(() => this.loadData())
          .catch(showError)
          .finally(() => {
            this.dialog = false
          })  
      },
  
      update() {
        this.error = false
        const id = this.object.id
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        const objects = this.object

        const startDate = this.object.startDate.split('/')
        const parseDate = `${startDate[2]}-${startDate[1]}-${startDate[0]}` 
        objects.startDate = parseDate

        if(objects.status == 'Finalizado'){
          objects.endDate = new Date
        }

        delete objects.id
        delete objects.name
  
        delete objects.createdAt
        delete objects.updatedAt
        delete objects.deletedAt
        axios
          .put(url, objects)
          .then(() => this.loadData())
          .catch(showError)
          .finally(() => {
            this.dialog = false
          })
      },
      
      remove(id) {
        if (confirm("Remove?")) {
          const url = `${baseApiUrl}/${this.baseRoute}/${id}`
          axios
            .delete(url)
            .then(() => this.loadData())
            .catch(showError)
        }
      },

      activity(id) {
        const route = {
          name: 'PlanoAcaoAtividade',
          params: {id: id}
        }
        this.$router.push(route)
      },
      
      resource(id) {
        const route = {
          name: 'PlanoAcaoRecurso',
          params: {id: id}
        }
        this.$router.push(route)
      },

      parseDate(date) {
        if (!date) return null
        const [month, day, year] = date.substring(0, 11).split("/")
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
      },

      getStatusColor(status) {
        if (status == "Em andamento") {
          return "#FBC02D"
        }
        else if (status == "Finalizado") {
          return "#4CAF50"
        }
        else if (status == "Pendente") {
          return "#757575" 
        }
      },

      getRiskColor(risk) {
        if (risk == 'Baixo') {
          return "#43A047"
        }
        else if (risk == 'Médio') {
          return "#FBC02D"
        }
        else if (risk == 'Alto'){
          return "#F44336"
        }
      },
    },
  
    mounted() {
      this.loadData()
      this.loadPerson()
    },
  
  }
  </script>

<style>
.full-height {
  height: 80vh;
}
</style>